import ApiService from "@/core/services/api.service";

const url = process.env.VUE_APP_API_ADMIN;

class News {
  get(id) {
    return ApiService.get(url, "news/" + id);
  }

  getAll(params, cancelToken = undefined) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "news?" + options, headers);
  }

  store(data) {
    return ApiService.post(url + "/news", data);
  }

  update(id, data) {
    return ApiService.put(url + "/news/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/news/" + id);
  }

  adminNews(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "news/active" + options);
  }

  tenantNews(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(process.env.VUE_APP_API_TENANT, "news" + options);
  }
}

export default new News();
