<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-body">
      <h1 class="text-capitalize">
        {{ $t("dashboard.hello") }} {{ currentUserPersonalInfo.name }}!
      </h1>
      <p>{{ $t("dashboard.welcomeText") }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GeneralDashboardAppsWelcome",
  computed: {
    ...mapGetters(["currentUserPersonalInfo"])
  }
};
</script>
