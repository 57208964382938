<template>
  <div>
    <div class="grid-stack">
      <div
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="0"
        data-gs-width="12"
        data-gs-height="2"
      >
        <Welcome />
      </div>

      <div
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="2"
        :data-gs-width="12"
        :data-gs-height="
          userType === 'admin' || userType === 'partner' ? '6' : '3'
        "
      >
        <Sections :user-type="userType" />
      </div>

      <div
        v-if="appVersion < 2"
        class="grid-stack-item"
        :data-gs-x="0"
        :data-gs-y="userType === 'admin' || userType === 'partner' ? '8' : '5'"
        data-gs-width="8"
        data-gs-height="8"
      >
        <News />
      </div>

      <div
        v-if="appVersion < 2"
        id="ClientsContactPersons"
        class="grid-stack-item"
        data-gs-x="10"
        data-gs-y="0"
        data-gs-width="2"
        data-gs-height="5"
      >
        <ClientsContactPersons />
      </div>
      <div
        v-if="appVersion < 2"
        id="ClientsContactPartner"
        class="grid-stack-item"
        data-gs-x="10"
        data-gs-y="5"
        data-gs-width="2"
        data-gs-height="4"
      >
        <ClientsContactPartner />
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import $ from "jquery";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.css";
import ClientsContactPersons from "@/components/General/Dashboard/Apps/Clients/ContactPerson";
import ClientsContactPartner from "@/components/General/Dashboard/Apps/Clients/Partner";
import Welcome from "@/components/General/Dashboard/Apps/Welcome";
import Sections from "@/components/General/Dashboard/Apps/Sections";
import News from "@/components/General/Dashboard/Apps/News";
import { mapGetters } from "vuex";
import {bus} from "@/main";

export default {
  name: "GeneralDashboardIndex",
  components: {
    ClientsContactPersons,
    ClientsContactPartner,
    Welcome,
    Sections,
    News
  },
  beforeRouteLeave(to, from, next) {
    $(".grid-stack").remove();
    next();
  },
  data() {
    return {
      gridstack: null
    };
  },
  computed: {
    ...mapGetters(["userType"])
  },
  mounted() {
    if (this.appVersion >= 2) {
      this.$router.push({ name: "projectWelcome" }).then(() => {
        bus.$emit("areaChangedFinished", 1);
      });
      return;
    }

    this.$forceUpdate();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("menu.dashboard") }
    ]);
    this.loadGridstack();
  },
  methods: {
    loadGridstack() {
      var options = {
        cellHeight: 80,
        margin: 10
      };

      this.gridstack = GridStack.init(options);
    }
  }
};
</script>
