<template>
  <div id="news-card" class="card card-custom grid-stack-item-content">
    <div class="card-header align-items-center">
      <div class="card-title h2">{{ $t("dashboard.yediNews") }}</div>
      <div>
        <button class="btn btn-clean btn-icon" @click="reloadNews">
          <i class="fal fa-arrows-rotate" />
        </button>
      </div>
    </div>
    <div class="card-body">
      <div v-for="(article, i) in news" :key="i">
        <h4 class="mb-0">{{ article.title }}</h4>
        <div class="mb-2">
          <span>{{ formatReleaseData(article.date) }}</span>
        </div>
        <div v-html="article.content"></div>
        <div class="separator separator-solid my-5"></div>
      </div>
      <b-skeleton-wrapper :loading="isBusy">
        <template #loading>
          <div v-for="i in 2" :key="i">
            <b-skeleton width="55%" height="30px" class="mb-2"></b-skeleton>
            <b-skeleton width="20%" class="mb-5"></b-skeleton>
            <b-skeleton width="100%" height="20px" class="mb-1"></b-skeleton>
            <b-skeleton width="100%" height="20px" class="mb-1"></b-skeleton>
            <b-skeleton width="100%" height="20px" class="mb-1"></b-skeleton>
            <b-skeleton width="80%" height="20px" class="mb-1"></b-skeleton>
            <div class="separator separator-solid my-5"></div>
          </div>
        </template>
      </b-skeleton-wrapper>
      <div class="text-center">
        <button
          v-if="moreNewsAvailable"
          class="btn btn-primary mb-5"
          :disabled="isBusy"
          @click="loadNews"
        >
          {{ $t("dashboard.loadMore") }} <i class="fal fa-arrows-rotate" />
        </button>
        <div v-else-if="!isBusy && !news.length" class="pb-5">
          {{ $t("dashboard.noNewsYet") }}
        </div>
        <div v-else-if="!isBusy" class="pb-5">
          {{ $t("dashboard.allNewsLoaded") }}
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      :visible="lightbox.visible"
      :imgs="lightbox.images"
      @hide="lightbox.visible = false"
    />
  </div>
</template>

<script>
import News from "@/components/Admins/News/news";
import { formatDate } from "@/components/Tools/modifiers";
import $ from "jquery";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "GeneralDashboardAppsNews",
  data() {
    return {
      news: [],
      size: 5,
      page: 1,
      meta: {},
      isBusy: false,
      lightbox: {
        visible: false,
        images: []
      }
    };
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    moreNewsAvailable: function () {
      return this.meta.current_page
        ? this.meta.current_page < this.meta.last_page
        : false;
    }
  },
  mounted() {
    this.loadNews();
    this.addClickHandler();
  },
  methods: {
    loadNews() {
      let apiKey = this.$store.getters.apiToken?.length;
      apiKey ? this.loadNewsTenant() : this.loadNewsAdmin();
    },
    loadNewsAdmin() {
      if (ApiService.isCached("newsAdmin")) {
        this.news = ApiService.isCached("newsAdmin");
        return;
      }
      this.isBusy = true;
      let params = {
        page: this.page,
        size: this.size
      };
      News.adminNews(params)
        .then(response => {
          this.news = this.news.concat(response.data.data);
          this.page++;
          this.meta = response.data.meta;
          this.isBusy = false;
          ApiService.setCache("newsAdmin", this.news, 1000 * 60 * 30);
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
          this.isBusy = false;
        });
    },
    loadNewsTenant() {
      if (ApiService.isCached("newsTenant")) {
        this.news = ApiService.isCached("newsTenant");
        return;
      }
      this.isBusy = true;
      let params = {
        page: this.page,
        size: this.size
      };
      News.tenantNews(params)
        .then(response => {
          this.news = this.news.concat(response.data.data);
          this.page++;
          this.meta = response.data.meta;
          this.isBusy = false;
          ApiService.setCache("newsTenant", this.news, 1000 * 60 * 30);
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
          this.isBusy = false;
        });
    },
    formatReleaseData(date) {
      return formatDate(date, "DD.MM.YYYY");
    },
    reloadNews() {
      this.page = 1;
      this.news = [];
      this.loadNews();
    },
    addClickHandler() {
      let me = this;
      $("#news-card").on("click", function (e) {
        let $target = $(e.target);
        if ($target.prop("nodeName") !== "IMG") return;
        me.lightbox.images = [$target.prop("src")];
        me.lightbox.visible = true;
      });
    }
  }
};
</script>

<style lang="scss">
#news-card {
  img {
    &:not(.vel-img) {
      cursor: zoom-in;
    }
  }
}
</style>
