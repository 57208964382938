<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-body pa-2 d-flex">
      <div class="row m-0">
        <div v-for="(area, key) in availableAreas" :key="key" class="col-6">
          <div
            class="section d-block px-4 py-2 px-sm-6 py-sm-4 border border-secondary rounded-lg cursor-pointer h-100"
            @click="selectMenu(area.menuKey)"
          >
            <i
              class="d-block icon-3x text-secondary mb-3"
              :class="area.icon"
              style="line-height: 1"
            />
            <div
              class="text-secondary font-size-h5 mb-3"
              style="line-height: 1"
            >
              {{ area.label }}
            </div>
            <div class="text-secondary">{{ area.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import menu from "@/core/config/menu.json";

export default {
  name: "GeneralDashboardAppsSections",
  props: {
    userType: String
  },
  computed: {
    availableAreas: function () {
      let areas = [
        {
          icon: "fa-light fa-clipboard-list-check",
          label: this.$t("menu.areaProject"),
          desc: this.$t("dashboard.projectShortDescription"),
          menuKey: 0
        },
        {
          icon: "fal fa-users",
          label: this.$t("menu.areaTenant"),
          desc: this.$t("dashboard.tenantShortDescription"),
          menuKey: 1
        }
      ];
      if (this.userType === "partner" || this.userType === "admin") {
        areas.push({
          icon: "fal fa-user-tie",
          label: this.$t("menu.areaPartner"),
          desc: this.$t("dashboard.partnerShortDescription"),
          menuKey: 2
        });
      }

      if (this.userType === "admin") {
        areas.push({
          icon: "fal fa-key",
          label: this.$t("menu.areaAdmin"),
          desc: this.$t("dashboard.adminShortDescription"),
          menuKey: 3,
          requiredUserType: "admin"
        });
      }

      return areas;
    },
    randInt() {
      return 2;
    }
  },
  methods: {
    selectMenu(menuIndex) {
      bus.$emit("menuAreaChanged", menu[menuIndex]);
    }
  }
};
</script>

<style lang="scss">
.section:hover {
  &.border-secondary,
  .text-secondary {
    border-color: #ff3554 !important;
    color: #ff3554 !important;
  }
}
</style>
